<template>
  <div>
    <vx-card actionable class="cardx" title="Purchases Approval">
      <vs-row style="margin-bottom: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              SPOC
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedBDE"
                :multiple="true"
                :options="treeDataBDE"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
            <vs-button
              radius
              color="dark"
              type="gradient"
              icon="search"
            ></vs-button>
            <vs-dropdown-menu class="loginx">
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <v-select
                    :filterable="false"
                    :options="mwb_names"
                    @search="autoCompleteName"
                    placeholder="Name"
                    v-model="value1"
                    style="width:100%"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="getEnrollmentstoApprove()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="9"
                >
                  <v-select
                    v-if="spocLogged"
                    :options="SpocCityPrefilled"
                    v-model="SpocPrefilled"
                    :clearable="false"
                    style="margin-top:10%; margin-right: 1%"
                  />
                  <vs-input
                    type="text"
                    label-placeholder="Can-Id"
                    v-model="value5"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="getEnrollmentstoApprove()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Mobile"
                    v-model="value3"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="getEnrollmentstoApprove()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Email"
                    v-model="value4"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="getEnrollmentstoApprove()"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshdata"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b>Search Criteria:</b>
        </vs-col>
        <vs-col
          style="overflow-y: scroll"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <div v-for="(chip, index) in searchCriteria" :key="index">
            <vs-chip
              v-if="chip !== 'divider'"
              closable
              @click="removechip(chip)"
              color="primary"
            >
              <b>{{ chip }}</b>
            </vs-chip>
            <div v-else>
              <div class="vl"></div>
            </div>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
        >
          <vs-button
            color="dark"
            type="gradient"
            icon="search"
            @click="getEnrollmentstoApprove()"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-tabs :color="tabColor">
        <vs-tab label="Pending" @click="changeFilter('pending')">
          <datatable></datatable>
        </vs-tab>
        <vs-tab label="Approved" @click="changeFilter('approved')">
          <datatable></datatable>
        </vs-tab>
        <vs-tab label="Rejected" @click="changeFilter('rejected')">
          <datatable></datatable>
        </vs-tab>
      </vs-tabs>
      <vs-pagination
        style="margin-top: 2%"
        :total="tablelinks"
        v-model="currentTablePage"
      ></vs-pagination>
    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import datatable from "../components/pagesComponents/PurchasesApprovalDatatable.vue";
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import vSelect from "vue-select";
export default {
  components: {
    datatable,
    vSelect,
    Treeselect
  },
  data() {
    return {
      dropdownKey:0,
      currentTablePage: 1,
      tablelinks: 1,
      city: "",
      cities: [],
      filter: "pending",
      tabColor: "warning",
      initSelectedBDE: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: []
        }
      ],
      rawBde: [],
      rawcities: [],
      searchCriteria: [],
      value1: "",
      value3: "",
      value4: "",
      value5: "",
      mwb_names: [],
      nameAutocomplete: [],
      spocLogged: false,
      canIdWidth: "100px",
      logged_in_spoc_id :"",
    };
  },
  watch: {
    city() {
      this.analyzePagination();
      // this.getEnrollmentstoApprove();
    },
    filter(value) {
      this.changeTabColor(value);
    },
    initSelectedBDE: function() {
      this.mergeAllSearch();
    },
    currentTablePage() {
      this.getEnrollmentstoApprove();
    }
  },
  mounted() {
    this.logged_in_spoc_id = localStorage.getItem("spoc_id");
    // this.getEnrollmentstoApprove();
    this.getSpocs();
    EventBus.$on("reloadapprovals", () => {
      // this.getEnrollmentstoApprove();
      this.spocLogged = false;
      this.canIdWidth = "100%";
      this.analyzePagination();
    });
  },
  methods: {
    refreshdata() {
      console.log("testing");
    },
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      this.fetchMwbs(loading, search, this);
    },
    fetchMwbs: _.debounce((loading, search, vm) => {
      let url = `${constants.ADMIN_SERVER_API}searchMwbName?name=${escape(search)}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          vm.mwb_names = response.data.map(lead => lead.person_name);
          loading(false);
        });
    }, 350),
    changeTabColor(value) {
      switch (value) {
        case "pending":
          this.tabColor = "warning";
          break;
        case "approved":
          this.tabColor = "success";
          break;
        case "rejected":
          this.tabColor = "danger";
          break;
      }
    },
    getSpocIds() {
      let bde = [];
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc"
      ) {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              child.children.forEach(acutal_spoc => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach(child => {
              if (child.label === sort) {
                child.children.forEach(acutal_spoc => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      let bdeid = [];
      bde.forEach(spoc => {
        this.rawBde.forEach(raw => {
          if (raw.full_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      return bdeid.join();
    },
    changeFilter(filter) {
      this.filter = filter;
      this.analyzePagination();
    },
    analyzePagination() {
      if (this.selectedpagination === 1) {
        this.getEnrollmentstoApprove();
      } else {
        this.selectedpagination = 1;
      }
    },
    getEnrollmentstoApprove() {
      this.dropdownKey = Math.random()
      axios
        .get(`${constants.ADMIN_SERVER_API}getPurchasesForApproval`, {
          params: {
            page: this.currentTablePage,
            name: this.value1,
            spoc_ids: this.getSpocIds(),
            mobile: this.value3,
            email: this.value4,
            identity: this.value5,
            status: this.filter
          },
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          console.log(response);
          response.data.data.data.forEach(lead => {
            lead.engagements = [];
            lead.mobile = [];
            lead.emails = [];
          });
          this.currentTablePage = response.data.data.current_page;
          this.tablelinks = response.data.data.last_page;
          this.$store.commit("MOUNT_MWB_LEADS", response.data.data.data);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getSpocs() {
      let url = `${constants.ADMIN_SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          this.loggedin = response.data.logged_in_user_id;
          this.spoc_names = response.data.spocs;
          this.extractSpocByCity(response);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc"
      ) {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              child.children.forEach(acutal_spoc => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach(child => {
              if (child.label === sort) {
                child.children.forEach(acutal_spoc => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria.push(sort);
          }
        });
      }
      // this.searchCriteria.push("divider");
      // this.initSelectedCourses.forEach(sort => {
      //   if (sort === "All") {
      //     this.treeDataCourses[0].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else {
      //     this.searchCriteria.push(sort);
      //   }
      // });
      // this.searchCriteria.push("divider");
      // this.initSelectedLevels.forEach(sort => {
      //   if (sort === "All") {
      //     this.treeDataLevels[0].children[0].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //     this.treeDataLevels[0].children[1].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //     this.treeDataLevels[0].children[2].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else if (sort === "P1") {
      //     this.treeDataLevels[0].children[0].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else if (sort === "P2") {
      //     this.treeDataLevels[0].children[1].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else if (sort === "P3") {
      //     this.treeDataLevels[0].children[2].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else {
      //     this.searchCriteria.push(sort);
      //   }
      // });
      // this.searchCriteria.push("divider");
      // if (this.initSelected !== "" && this.initSelected !== undefined) {
      //   this.searchCriteria.push(this.initSelected);
      // }
      // this.searchCriteria.push("divider");
      // this.infosearch.forEach(info => {
      //   this.searchCriteria.push(info);
      // });
    },
    removechip(chip) {
      // console.log(chip);
      // console.log(this.treeDataBDE);
      // console.log(this.treeDataLevels);
      // console.log(this.treeDataCourses);
      // console.log(this.treeDatafocus);
      // if (this.infosearch.indexOf(chip) !== -1) {
      //   this.infosearch.splice(this.infosearch.indexOf(chip), 1);
      // }
      let chipPlacement = "";
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc"
      ) {
        this.treeDataBDE[0].children.forEach(child => {
          child.children.forEach(child2 => {
            if (child2.label === chip) {
              chipPlacement = "bde";
            }
          });
        });
      } else {
        this.treeDataBDE[0].children.forEach(child => {
          if (child.label === chip) {
            chipPlacement = "bde";
          }
        });
      }
      /* this.treeDataCourses[0].children.forEach(child => {
        if (child.label === chip) {
          chipPlacement = "courses";
        }
      });
      this.treeDataLevels[0].children.forEach(child => {
        if (chip === "M7" || chip === "N/A" || chip === "DND") {
          chipPlacement = "levels";
        } else {
          if ("children" in child) {
            child.children.forEach(chi => {
              if (chi.label === chip) {
                chipPlacement = "levels";
              }
            });
          }
        }
      });
      if (this.initSelected === chip) {
        this.initSelected = undefined;
      } */
      switch (chipPlacement) {
        case "bde":
          if (this.initSelectedBDE.indexOf(chip) === -1) {
            if (
              localStorage.getItem("role") === "cm4" ||
              localStorage.getItem("role") === "wc"
            ) {
              if (this.initSelectedBDE.indexOf("All") !== -1) {
                this.initSelectedBDE = [];
                this.treeDataBDE[0].children.forEach(child => {
                  child.children.forEach(child2 => {
                    if (child2.label !== chip) {
                      this.initSelectedBDE.push(child2.label);
                    }
                  });
                });
              } else {
                let city = "";
                this.treeDataBDE[0].children.forEach(child => {
                  child.children.forEach(child2 => {
                    if (child2.label === chip) {
                      city = child.label;
                    }
                  });
                });
                this.initSelectedBDE.splice(
                  this.initSelectedBDE.indexOf(city),
                  1
                );
                this.treeDataBDE[0].children.forEach(child => {
                  if (child.label === city) {
                    child.children.forEach(child2 => {
                      if (child2.label !== chip) {
                        this.initSelectedBDE.push(child2.label);
                      }
                    });
                  }
                });
              }
            } else {
              this.initSelectedBDE = [];
              this.treeDataBDE[0].children.forEach(child => {
                if (child.label !== chip) {
                  this.initSelectedBDE.push(child.label);
                }
              });
            }
          } else {
            this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(chip), 1);
          }
          break;
        // case "courses":
        //   if (this.initSelectedCourses.indexOf(chip) === -1) {
        //     this.initSelectedCourses = [];
        //     this.treeDataCourses[0].children.forEach(child => {
        //       if (child.label !== chip) {
        //         this.initSelectedCourses.push(child.label);
        //       }
        //     });
        //   } else {
        //     this.initSelectedCourses.splice(
        //       this.initSelectedCourses.indexOf(chip),
        //       1
        //     );
        //   }
        //   break;
        // case "levels":
        //   if (this.initSelectedLevels.indexOf("All") !== -1) {
        //     this.initSelectedLevels = [];
        //     this.treeDataLevels[0].children.forEach(child => {
        //       child.children.forEach(chi => {
        //         if (chi.label !== chip) {
        //           this.initSelectedLevels.push(chi.label);
        //         }
        //       });
        //     });
        //   } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
        //     this.initSelectedLevels.splice(
        //       this.initSelectedLevels.indexOf(chip),
        //       1
        //     );
        //   } else {
        //     let childProperty = "";
        //     this.treeDataLevels[0].children.forEach(child => {
        //       if ("children" in child) {
        //         child.children.forEach(chi => {
        //           if (chi.label === chip) {
        //             childProperty = child.label;
        //           }
        //         });
        //       }
        //     });
        //     this.initSelectedLevels.splice(
        //       this.initSelectedLevels.indexOf(childProperty),
        //       1
        //     );
        //     this.treeDataLevels[0].children.forEach(child => {
        //       if (child.label === childProperty) {
        //         if ("children" in child) {
        //           child.children.forEach(chi => {
        //             if (chi.label !== chip) {
        //               this.initSelectedLevels.push(chi.label);
        //             }
        //           });
        //         }
        //       }
        //     });
        //   }
      }
    },
    showTreeBox() {
      return this.treeDataBDE;
    },
    extractSpocByCity(response) {
      this.rawBde = response.data.spocs;
      this.treeDataBDE[0].children = [];
      this.rawcities = [];
      var unique = response.data.spocs
        .map(name => {
          return {
            count: 1,
            name: name.city
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.rawcities.push(key);
          var obj = {
            id: key,
            label: key,
            children: []
          };
          this.treeDataBDE[0].children.push(obj);
        }
      }
      response.data.spocs.forEach(server_spoc => {
        this.treeDataBDE.forEach(bde => {
          bde.children.forEach(bdechid => {
            if (bdechid.label === server_spoc.city) {
              // var obj = {
              //   id: server_spoc.full_name,
              //   label: server_spoc.full_name
              // };
              // console.log(obj)
              // bdechid.children.push(obj);
             
                if(this.logged_in_spoc_id == 132 || this.logged_in_spoc_id == 937  || this.logged_in_spoc_id == 39){
                      let obj = {
                        id: server_spoc.full_name,
                        label:server_spoc.full_name,
                      };
                  bdechid.children.push(obj);
                }
                 else{
                    if(server_spoc.full_name.substring(0,1) != 'X'){
                      let obj = {
                          id: server_spoc.full_name,
                          label: server_spoc.full_name,
                      };
                      bdechid.children.push(obj);
                    }
                }
            }
          });
        });
      });
      this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
        this.treeDataBDE[0].children
      );
      if (this.rawBde.indexOf(this.searchIn) !== -1) {
        this.initSelectedBDE.push(this.searchIn.name);
      }
      // this.city = this.cities[0];
      this.getEnrollmentstoApprove();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
.vs-table--tbody-table tr {
  font-family: sans-serif;
  /* font-size: smaller; */
}
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}
.vs-dropdown--menu {
  width: 300px;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap {
  margin-right: 1%;
}
.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.scrollbar {
  overflow-y: scroll;
}
</style>
